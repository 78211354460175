<script>
    import { params, goto } from '@roxi/routify';
    import LessonNav from '../_components/LessonNav.svelte'
    
    export let lesson
  
    let isOpen = false
    let heightVar = '0%'

    const toggle = (state = !isOpen) => {
        isOpen = state ? !isOpen : false
        heightVar = (heightVar === '0%') ? '100%' : '0%'
    };

    const navContent = (lessonId) => {
        const courseId = $params ? $params.course : ''
        const prevLessonId = $params ? $params.lessons : ''
        if (courseId) {
            const selectedElement = document.getElementById(`list_${prevLessonId}`)
            if (selectedElement) {
                selectedElement.style.background = "transparent"
            }
            toggle()
            $goto(`/classes/${courseId}/${lessonId}`)
        }
    }

    $: id = lesson ? lesson.id ? lesson.id : '0' : '0'
    $: name = lesson ? lesson.name ? lesson.name : '' : ''
    

</script>
<style>

    .lessonNav {
        width: 100%;
        z-index: 10;
        text-align: justify
    }
    
</style>

<button id={id} class="w-100 tl f6 f5-m f5-l no-underline bg-transparent p0 m0 bn white pointer" on:click={navContent(id)}>
    { name }
</button>
{#if isOpen}
    <div class="lessonNav">
        <LessonNav {lesson} {heightVar} />
    </div>
{/if}