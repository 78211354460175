<script>
    import { url } from '@roxi/routify'
    export let courseId
</script>

<style>
    
    .frameShadow {
        border-radius: .5rem;
        border-color: transparent;
        background: #242424;
        box-shadow:  20px 20px 20px #121212;
    }

</style>

<div class="w-100 pt4">
    <div class="w-100 w-90-m w-90-l pv3 center frameShadow">
        <a class="w-100 no-underline pointer pl4 white flex items-center" href="{$url(`/classes/${courseId}/calendar`)}">
            <img height="26" src="/calendar.svg" alt="Calendar icon" /><span class="pl3 f5">Course Calendar</span>
        </a>
    </div>
</div>