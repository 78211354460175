<script>
    export let course
    import { goto } from '@roxi/routify'

    // const loadCourse = () => {
    //     if (course) {
    //         if (course.id) {
    //             $goto(`/classes/${course.id}/welcome`)
    //         }
    //     }
    // }

    $: state = {
        id: course ? course.id ? course.id : '' : '',
        name: course ? course.name ? course.name : '' : '',
        startDate: course ? course.startDate ? course.startDate : '' : '',
        headerColor: course ? course.headerColor ? course.headerColor : '#FF9D00' : '#FF9D00',
        duration: course ? course.duration ? course.duration : '' : '',
        instructors: course ? course.instructor ? course.instructor : [] : [],
    }
</script>


<style>
    .frameShadow {
        border-radius: 1rem;
        box-shadow:  20px 20px 20px #121212;
    }

    .bg-homeschool-1 {
        background: #242424
    }

    .card {
        width: 20rem;
        height: 9rem;
    }
    .cardHead {
        -webkit-clip-path: polygon(100% 0%, 0% 0%, 0% 0%, 0% 250%);
        clip-path: polygon(100% 0%, 0% 0%, 0% 0%, 0% 250%);
        height: 100%;
    }

    h2 {
        font-size: 1.25rem;
    }

    .cardFooter {
        position: absolute;
        bottom: 0.5rem;
        right: 0rem;
    }
    
</style>

<a class="no-underline pointer grow " href={`/classes/${state.id}/welcome`}>
    <div class="relative w-100">
        <div class="dt card bg-homeschool-1 ba br3 b--transparent frameShadow white">
            <div class="dt-row">
                <div class="dtc w-60 cardHead br3 br--left b--transparent" style="background: { state.headerColor };" >
                    <h2 class="mv0 pv2 pl2 w-80">
                        {state.name}
                    </h2>
                </div>
                <div class="dtc w-40 relative">
                    {#if state.instructors}
                        {#each state.instructors as instructor}
                        <div class="pb1 pr2 f5 tr">
                            {instructor.name}
                        </div>
                        {/each}
                    {/if}
                </div>
            </div>
        </div>
        <div class="cardFooter tr white">
            {#if state.duration}
                {#if state.duration !== '-'}
                <div class="f5 pr2 pt1">{state.duration}</div>
                {/if}
            {/if}
            <div class="f5 b pr2">{state.startDate}</div>
        </div>
    </div>
</a>
