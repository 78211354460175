<script>
    import { goto } from '@roxi/routify'
    import { user, validation, checkAuth } from '../store/user.js'
    import { login } from '@esmodule/openid-implicit-grant'
    import { authentication } from '../../config/index.js'
    import { afterUpdate } from 'svelte'
    import SignInGithub from './_components/SignInGithub.svelte'
    import SignInGoogle from './_components/SignInGoogle.svelte'
    import SignInUserDB from './_components/SignInUserDB.svelte'
    
    const loginUser = (provider) =>{
        authentication['connection'] = provider
        const loginConfig = login(authentication)

        if (loginConfig) {
            if (loginConfig.validation) {
                validation.set(loginConfig.validation)
            }
        }

        const authUrl = loginConfig ? loginConfig.url ? loginConfig.url : '' : ''
        if (authUrl) {
            window.location.href = authUrl
        } else {
            console.error('authUrl not specified')
        }
    }
    
    let isAuthenticated = checkAuth()
    afterUpdate(()=>{
            if (isAuthenticated) {
                $goto('/home')
            } 
        })
</script>
<style>
    .frameShadow {
        border-radius: 0.5rem;
        box-shadow:  20px 20px 20px #121212;
    }

    .bg-homeschool-2 {
        background: #343434
    }

    .loginBox {
        width: 340px;
    }

</style>

<div>
    <div class="relative">
        <div class="w-100 dt vh-75">
            <div class="dtc w-100 v-mid tc f4">
                {#if !isAuthenticated}
                    <div class="center w-100 mw6-m mw7-l flex flex-wrap justify-center"> 
                        <div class="pv3 white w-100 w-50-m w-50-l tl">
                            <p class="f3 baskerville">Welcome to Homeschool by Senzo. The place to learn for the modern developer.</p>
                            <p class="avenir f45 pt3">Please login using one of the available options.</p>
                        </div>
                        <div class="frameShadow loginBox center p3 ba br1 b--transparent bg-homeschool-2 "> 
                            <div class="center">
                                <div class="pv3">
                                    <SignInGithub on:login="{()=>loginUser('github')}" />
                                </div>
                                <div class="pv3">
                                    <SignInGoogle on:login="{()=>loginUser('google-oauth2')}" />
                                </div>
                                <div class="pv3">
                                    <SignInUserDB on:login="{()=>loginUser('SenzoDB')}" />
                                </div>
                            </div>
                        </div>
                    </div>
                {/if}
            </div>
        </div>
    </div>
</div>


