<script>
  import { Router } from "@roxi/routify";
  import { routes } from '../.routify/routes';
  import { client } from './js/graphql/client.js';  
  import { setClient } from 'svelte-apollo';
  import * as Sentry from '@sentry/browser';
  import { Integrations } from '@sentry/tracing';

  // init Sentry
  Sentry.init({
    dsn: 'https://5686e1ec276e46c2bd99dbc93d3262c9@o441521.ingest.sentry.io/5411784',
    release: "homeschool-svelte@" + process.env.npm_package_version,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
  });

  // init svelte-apollo
  setClient(client);
</script>

<Router {routes} />
