<script>
    import CalendarInvite  from './CalendarInvite.svelte'
    export let calendarEvents = []
</script>

<style>
    .border-underline {
        border-bottom-color: rgba(255,255,255,0.5);
        border-bottom-width: 1px;
        border-bottom-style: dotted;
    }

    .w-45 {
        width: 45%;
    }
</style>

<div class="p1 p3-m mb4 bg-homeschool-1 frameShadow">
    <div id='calendarView' class="relative center w-100 ph3 pt2 pb1 white">
        <div class="w-100 mw8-l center">
            <h2 class="f3 m0">Calendar</h2>
            <div class="cf dn db-m">
                <div class="fr w-45 pv2 tc f5">Calendar Invites</div>
            </div>
            <div class="pb2 cf">
                {#each calendarEvents as calendarEvent}
                    <div class="pr2 pt3 pb2 flex items-center justify-between border-underline">
                        <CalendarInvite {calendarEvent} />
                    </div>
                {/each}
            </div>
        </div>
    </div>
</div>