<script>
	import { createEventDispatcher } from 'svelte';

	const dispatch = createEventDispatcher();
</script>

<style>
    .loginBtn {
        background: #ffffff;
        width: 300px;
        height: 54px;
        border: transparent;
        border-radius: .25rem;
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
    .loginBtn:hover {
        cursor:pointer;
    }
    .identityLogo {
        padding-top: 4px;
        padding-left: 4px;
        width: 42px;
        vertical-align: middle;
    }
    .identityText {
        color: #484848;
        width: 100%;
        font-family: roboto, sans-serif;
        text-align: center;
        font-size: 1.125rem;
        font-weight: 500;
        vertical-align: middle;
    }

    button:focus {
        border-color: transparent!important;
        outline: 0 !important;
    }

    .buttonShadow {
        border-radius: 1rem;
        box-shadow: 10px 10px 10px rgba(10,10,10,0.7);
    }
</style>


<button class="loginBtn buttonShadow" on:click={()=>dispatch('login')}>
    <div class="identityLogo">
        <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet">
            <g id="github-avatar">
            <rect width="48" height="48" fill="white"/>
            <path id="Fill 4" fill-rule="evenodd" clip-rule="evenodd" d="M24.4984 7C14.836 7 7 14.8037 7 24.4308C7 32.1318 12.0138 38.6643 18.9678 40.9691C19.8434 41.1296 20.1625 40.5914 20.1625 40.1291C20.1625 39.7161 20.1474 38.6193 20.1388 37.1652C15.2711 38.2181 14.2441 34.8282 14.2441 34.8282C13.448 32.8144 12.3007 32.2783 12.3007 32.2783C10.7117 31.1976 12.421 31.219 12.421 31.219C14.1775 31.3421 15.1014 33.0156 15.1014 33.0156C16.6624 35.6789 19.1977 34.9095 20.1947 34.4633C20.3537 33.3377 20.806 32.5694 21.3055 32.1339C17.4197 31.6941 13.3341 30.1982 13.3341 23.5191C13.3341 21.6166 14.0163 20.0597 15.1358 18.842C14.9553 18.4011 14.3547 16.6281 15.3077 14.2291C15.3077 14.2291 16.7762 13.7604 20.1195 16.0161C21.515 15.6287 23.0126 15.4361 24.5005 15.4286C25.9874 15.4361 27.4839 15.6287 28.8816 16.0161C32.2227 13.7604 33.6891 14.2291 33.6891 14.2291C34.6442 16.6281 34.0436 18.4011 33.8642 18.842C34.9858 20.0597 35.6626 21.6166 35.6626 23.5191C35.6626 30.2153 31.5706 31.6888 27.673 32.12C28.3004 32.6582 28.8601 33.7218 28.8601 35.3483C28.8601 37.6777 28.8386 39.5577 28.8386 40.1291C28.8386 40.5957 29.1545 41.1382 30.0418 40.968C36.9905 38.6579 42 32.1296 42 24.4308C42 14.8037 34.164 7 24.4984 7Z" fill="#161514"/>
            </g>
        </svg>
    </div>
    <div class="identityText">
        Sign in with Github
    </div>
</button>