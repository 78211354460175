<!--
    <svelte:head>
    <script src="https://cdn.jsdelivr.net/npm/@widgetbot/html-embed" async defer></script>
</svelte:head>
-->



<script>
    import TopNav from "./_components/TopNav.svelte"
    import { onMount } from 'svelte' 
    import { checkAuth, logoutUser  } from '../store/user.js'

    const unauthenticatedPaths = ['/', '/callback', '']

    let show = unauthenticatedPaths.includes(window.location.pathname)

    let isAuthenticated = ''
    // let expired = false

    onMount(()=>{
        isAuthenticated = checkAuth()
        // isAuthenticated = checkAuth()
        if (!show) {
            if (!isAuthenticated) {
                try {
                    // console.log('logoutUser')
                    logoutUser()
                    show = true
                    // $goto('/')
                    window.location.pathname = '/'
                } catch(error){
                    console.log(`Logout error. ${error}`)
                }
            } 
        }
    })  
    
</script>

<div class="w-100 z-999">
    <TopNav />
</div>
    
{#if isAuthenticated}
        <slot />
{:else if show}
    <slot />
{/if}
