<script>
    import "../../css/markdown.css"
    import md from '../../js/markdown/index.js'
    // import highlight from '../../js/markdown/highlight.js'
    import { afterUpdate } from 'svelte'
    import { getClient, query } from 'svelte-apollo';
    import { GET_MARKDOWN } from '../../js/graphql/queries.js';
    import { token } from '../../store/user.js'
    
    export let location = ''
    let oldLocation = ''
    
    $: locationId = location.substring(location.indexOf('/')+1, (location.length-3))
    
    const client = getClient()
    
    let markdownResponse = ''
   
    token.subscribe(() => {
        if ($token) {
            if (oldLocation !== location) {
                const queryConfig = { 
                    query: GET_MARKDOWN, 
                    variables: { 
                        location 
                    }, 
                    context: {
                        headers: {
                            Authorization: `Bearer ${$token}`
                        }
                    } 
                }
                markdownResponse = query(client, queryConfig).result()
                oldLocation = location
            }
        }
    })

    afterUpdate(async()=>{
        if ($token) {
            if (oldLocation !== location) {
                const queryConfig = { 
                    query: GET_MARKDOWN, 
                    variables: { 
                        location 
                    }, 
                    context: {
                        headers: {
                            Authorization: `Bearer ${$token}`
                        }
                    } 
                }
                markdownResponse = query(client, queryConfig).result()
                oldLocation = location
            }
        }
    })

    const renderContent = (markdownContent) => {
        const response = md(markdownContent)
        return response
    }
</script>

<style>
    .loader {
        border: 9px solid #505050; /* Light grey */
        border-top: 9px solid #909090; /* Blue */
        border-radius: 50%;
        width: 90px;
        height: 90px;
        animation: spin 2s linear infinite;
    }
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>

{#await markdownResponse}
    <div class="dt w-100 vh-25">
        <div class="dtc w-100 v-mid tc center">
            <div class="loader center"></div> 
        </div>
    </div>
{:then value}
    <div id={`ref_${locationId}`} class="w-100 mw7-l center markdown-body white primaryLink pb3 h-100">
        {@html renderContent(value.data.getMarkdown.markdown)}
    </div>
{:catch error}
    <div class="w-100 mw7-l center f5 white pb3 h-100">
        <p>Unable to retrieve document with error:</p>
        {error}
    </div>
{/await}