import gql from 'graphql-tag'

const GET_ACTIVE_CLASS = gql`
  query getActiveClass($id: ID!) {
    getActiveClass(id: $id) {
      id
      name
      headerColor
      startDate
      sections {
        id
        name
        theme
        startDate
        tags
        order
        lessons {
          id
          name
          order
          contents {
            type
            videoId
            location
            time
            attachments {
              location
              filename
            }
          }
        }
      }
      calendarEvents {
        title
        location
        description
        start
        end
        frequency
        interval
        count
      }
    }
  }
`

const GET_MARKDOWN = gql`
  query getMarkdown($location: String) {
    getMarkdown(location: $location) {
      markdown
    }
  }
`

const GET_ATTACHMENT = gql`
  query getAttachment($location: String) {
    getAttachment(location: $location) {
      url
    }
  }
`

const GET_CALENDAR = gql`
  query getActiveClassCalendar($classId: String) {
    getActiveClassCalendar(classId: $classId) {
      title
      location
      description
      start
      end
      frequency
      interval
      count
    }
  }
`

// const GET_LESSON = gql`
//   query getLesson($classId: ID!, $lessonId: ID!) {
//     getLesson(classId: $classId, lessonId: $lessonId) {
//       name
//       live
//       available
//       contents {
//         type
//         markdown
//         videoId
//       }
//     }
//   }
// `

const GET_USER_DASHBOARD = gql`
  query getUserDashboard {
    getUserDashboard {
      current {
        id
        name
        startDate
        endDate
        live
        headerColor
        snippet
        duration
        instructor {
          name
          profileURL
        }
      }
      upcoming {
        id
        name
        startDate
        endDate
        live
        headerColor
        snippet
        duration
        instructor {
          name
        }
      }
      previous {
        id
        name
        startDate
        endDate
        live
        headerColor
        duration
        instructor {
          name
        }
      }
    }
  }
`

export {
  GET_ACTIVE_CLASS,
  GET_ATTACHMENT,
  GET_MARKDOWN,
  GET_USER_DASHBOARD,
  GET_CALENDAR
  // GET_LESSON,
}
