<script>
    import { get } from 'svelte/store'
    import { goto, params } from '@roxi/routify' 
    import { token } from '../../../../store/user.js'
    // import TopNav from '../../../_components/TopNav.svelte'
    import ClassFragment from '../../../_fragments/ClassFragment.svelte'
    import { getClient, query } from 'svelte-apollo';
    import { GET_ACTIVE_CLASS } from '../../../../js/graphql/queries.js';

    export let lessons
    const client = getClient()

    $: lessonId = lessons ? lessons : '' 
    const courseId = $params ? $params.course ? $params.course : '' : ''

    const courseList = ['production-ready-serverless-202205','production-ready-serverless-202005', 'production-ready-serverless-202009', 'production-ready-serverless-202101', 'production-ready-serverless-202201', 'production-ready-serverless-202104', 'production-ready-serverless-202109', 'testing-serverless-apps-202006', 'reinvent-2020-review-202012', 'containers-on-aws-202011', 'nodejs-for-serverless-202101' ]
    const validCourse = (courseList.filter(courseName => { 
        return courseId === courseName }).length > 0) ? true : false

    if (!validCourse) {
        $goto('/')
    } 

    const queryConfig = { 
        query: GET_ACTIVE_CLASS, 
        variables: { 
            id: courseId 
        }, 
        context: {
            headers: {
                Authorization: `Bearer ${$token}`
            }
        } 
    }

    const course = query(client, queryConfig).result()

    
    // $: course.refetch({ id: courseId })

</script>

<style>
    .loader {
        border: 9px solid #505050; /* Light grey */
        border-top: 9px solid #909090; /* Blue */
        border-radius: 50%;
        width: 90px;
        height: 90px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

</style>

<div>
    <div class="w-100">
        {#await course}
            <div class="dt w-100 vh-75">
                <div class="dtc w-100 v-mid tc center">
                    <div class="loader center"></div> 
                </div>
            </div>
        {:then value}
            {#if validCourse}          
                <ClassFragment course={value} {lessonId}/>
            {/if}
        {/await}
    </div>
      
</div>