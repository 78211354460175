<script>
    import CourseView from './_fragments/CourseView.svelte'
    import { token, user } from '../store/user.js'
    import { getClient, query } from 'svelte-apollo';
    // import { onMount } from 'svelte'
    import { GET_USER_DASHBOARD } from '../js/graphql/queries.js';
    
    const client = getClient()

    let currentSession = ''

    let dashboard = false

    user.subscribe(async () => {
        if ($user){
            if (token) {
                if ($user.sessionId) {
                    if (currentSession !== $user.sessionId) {
                        const requeryConfig = { 
                            query: GET_USER_DASHBOARD,
                            variables: {
                                email: $user.email
                            },
                            context: {
                                headers: {
                                    Authorization: `Bearer ${$token}`
                                }
                            },
                            errorPolicy: 'all'
                        }
                        try {
                            dashboard = await query(client, requeryConfig).result()
                        } catch (error) {
                            dashboard = false
                            console.error(error)
                        }
                    }
                }  
            }
        }
    })

</script>

<style>
    .loader {
        border: 9px solid #505050; 
        border-top: 9px solid #909090;
        border-radius: 50%;
        width: 90px;
        height: 90px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

</style>

<div>
    {#if !dashboard}
        <div class="dt w-100 vh-75">
            <div class="dtc w-100 v-mid tc center">
                <div class="loader center"></div> 
            </div>
        </div>
    {:else}
        <div class="w-100">
            <CourseView {dashboard}/>
        </div>
    {/if}
</div>

