<script>
    import ExerciseList from '../_components/ExerciseList.svelte'
    import ContentView from './ContentView.svelte'
    import CalendarView from '../_components/CalendarView.svelte'
    import CalendarMenu from '../_components/CalendarMenu.svelte'

    export let lessonId
    export let course
    
    const getLessonContents = (lessonId) => {
        let contents = []
        if (lessonId !== 'welcome' || '') {
            const courseSections = course ? course.data ? course.data.getActiveClass ? course.data.getActiveClass.sections : [] : [] : []
            if (courseSections.length > 0) {
                for (let i = 0; i < courseSections.length; i++){
                    if (Array.isArray(courseSections[i].lessons)) {
                        for (let ii = 0; ii < courseSections[i].lessons.length; ii++) {
                            const currentLesson = courseSections[i].lessons[ii]
                            if (currentLesson.id === lessonId) {
                                if (Array.isArray(currentLesson.contents)) {
                                    contents = currentLesson.contents
                                }
                            }
                        }
                    }      
                }
            }
        }
        return { contents }
    }

    $: courseName = course ? course.data ? course.data.getActiveClass ? course.data.getActiveClass.name : '  ' : '  ' : '  '
    $: courseId = course ? course.data ? course.data.getActiveClass ? course.data.getActiveClass.id : '' : '' : ''
    $: headerColor = course ? course.data ? course.data.getActiveClass ? course.data.getActiveClass.headerColor : '#FF9D00' : '#FF9D00' : '#FF9D00'
    $: sections = course ? course.data ? course.data.getActiveClass ? course.data.getActiveClass.sections : [] : [] : []
    $: contents = course ? course.data ? course.data.getActiveClass ? getLessonContents(lessonId).contents : [] : [] : []
    $: startDate = course ? course.data ? course.data.getActiveClass ? course.data.getActiveClass.startDate : '' : '' : ''
    $: calendarEvents = course ? course.data ? course.data.getActiveClass ? course.data.getActiveClass.calendarEvents : [] : [] : []

</script>

<style>

    .playerWindow {
        width: 100%;
        z-index: 10;
    }

    .exerciseWindow {
        width: 28rem;
        z-index: 5;
    }

    @media screen and (min-width: 60em) {
        .playerWindow-ns {
            width: auto;
        }
    }

</style>

<div class="w-100 ph1 flex flex-wrap items-start relative">
    
    <div class="exerciseWindow"> 
        <ExerciseList {sections} {lessonId} {headerColor} {startDate} name={courseName}/>
        {#if calendarEvents.length > 0}
            <CalendarMenu {courseId} />
        {/if}
    </div>
    <div class="mh2 ml2-m ml2-l mr4-m mr4-l playerWindow playerWindow-ns flex-grow-1">
        {#if lessonId !== 'calendar'}
            <ContentView {contents} {startDate}/>
        {:else}
            <CalendarView {calendarEvents} />
        {/if}
    </div>
    
</div>



  