import MarkdownIt from 'markdown-it'
import highlight from './highlight.js'

export default source => {
  const md = new MarkdownIt({
    html: true,
    highlight
  })

  return source ? md.render(source) : '<h4> ... loading </h4>'
}
