<script>
    import { slide } from "svelte/transition"; 
    import { createEventDispatcher, onMount } from 'svelte';
    import ClassNavButton from './ClassNavButton.svelte'

    export let section
    export let isOpen = false
    
    let heightVar = '0%'

	const dispatch = createEventDispatcher();

    const { lessons } = section

    const toggle = (state = !isOpen) => {
        isOpen = state ? !isOpen : false
        heightVar = (heightVar === '0%') ? '100%' : '0%'
    };

    onMount(()=>{
        if (isOpen) {
            heightVar = (heightVar === '0%') ? '100%' : '0%'
        }
    })

    $: name = section ? section.name ? section.name : '' : ''
    $: theme = section ? section.theme ? section.theme : '' : ''
    $: startDate = section ? section.startDate ? section.startDate : '' : ''
    $: sectionId = section ? section.id ? section.id : '' : ''

</script>

<style>
    .menu-content {
        overflow: hidden;
        margin-left: 0%;
        padding-left: 0%
    }

    li:hover{
        background: #404040;
    }
     
</style>
<div>
    <input type="checkbox" id={sectionId} on:click={toggle} style="display: none"/>
    <label for={sectionId}>
        <div class="mh2 ml4-m ml4-l mr3-m mr3-l mb3 mt2 mt0-m mt0-l pv2 ph0 white pointer">
            <h3 class="mb1 mb2-m mb2-l fw4 m0 f5 f45-m f45-l tl">{ theme }</h3>
            <div class="flex justify-between items-center">
                <div class="f5 b">{ name }</div>
                <div class="f5 b">{ startDate }</div>
            </div>
        </div>
    </label>
   
    {#if isOpen}
        <ul class="menu-content list p0" transition:slide|local={{ duration: 300 }} style={`max-height:${heightVar}`}>
            {#each lessons as lesson}
                <li id={`list_${lesson.id}`} class="mh2 ml4-m ml4-l mr3-m mr3-l ph0 pv2">
                    <ClassNavButton {lesson} />
                </li>
            {/each}
        </ul>
    {/if}
</div>