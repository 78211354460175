<script>
    import { ICalendar, GoogleCalendar, OutlookCalendar } from 'datebook'
    import { afterUpdate } from 'svelte'
    import { url } from '@roxi/routify'

    export let calendarEvent

    let gcal
    let o365
    let ics

    function populateInvites () {
        if (calendarEvent) {
            const { title, location, description, start, end, frequency, interval, count } = calendarEvent

            const options = {
                title,
                location,
                description,
                start: new Date(start),
                end: new Date(end)
            }

            if (frequency && interval && count) {
                options.recurrence = {
                    frequency,
                    interval,
                    count
                }
            }

            gcal = new GoogleCalendar(options)
            gcal.setParam('crm', 'BUSY')
            gcal.setParam('trp', 'true')

            o365 = new OutlookCalendar(options)
            o365.setHost('outlook.office.com')
            o365.setParam('online', 'true')

            ics = new ICalendar(options)

            const icsAlarm = {
                action: 'DISPLAY',
                trigger: {
                    minutes: 10
                }
            }
            ics.addAlarm(icsAlarm)
        }
    }

    const dateString = (inputDate) =>{
        const workingDate = new Date(inputDate)
        const dateFormat = new Intl.DateTimeFormat([],{ year: "numeric", month: "long",day:"numeric", hour:"numeric",minute: "numeric", timeZoneName: 'short'})
        const dateString = dateFormat.format(workingDate)
        const delimiter = dateString.lastIndexOf(',') > -1 ? dateString.lastIndexOf(',') : dateString.lastIndexOf(':') -3
        const dateFragment = dateString.substring(0,delimiter)
        const timeFragment = dateString.lastIndexOf(',') > -1 ? dateString.substring(delimiter+2) : dateString.substring(delimiter) 
        return { dateFragment, timeFragment}
    }

    afterUpdate(()=>{populateInvites(calendarEvent.start)})
    
    $: title = calendarEvent ? calendarEvent.title : false
    $: location = calendarEvent ? calendarEvent.location : false
    $: date = calendarEvent ? dateString(calendarEvent.start) : false 
    $: gcalLink = gcal ? gcal.render() : false
    $: o365Link = o365 ? o365.render() : false
    $: icsLink = ics ? true : false
</script>

<style>
    .frameShadow {
        box-shadow:  10px 10px 10px #121212;
    }

    .bg-homeschool-2 {
        background: #353535
    }

    .link,
    .link:visited {
        text-decoration: none;
        font-weight: 600;
        color: #f8f8f8;
        font-size: 1rem;
        border-bottom-color: #db2b3e;
        border-bottom-style: dotted;
        border-bottom-width: 2px;
    }

    .p-button {
        padding-top: .65rem;
        padding-bottom: .65rem;
    }

    @media screen and (min-width: 48em) {
        .w-15-m {
            width: 15%;
        }
        .w-35-m {
            width: 35%;
        }
    }
</style>


<div class="w-100 flex flex-wrap items-center white">
    <div class="p2 tl w-30 w-20-m tl">
        {#if date}
            <div class="pv1">{ date.dateFragment }</div>
            <div class="pb1">{ date.timeFragment }</div>
        {/if}
    </div>

    <div class="p2 tl w-70 w-35-m">
        {#if location}
            <a class="link" href="{$url(location)}">{ title }</a>
        {:else}
            {title}
        {/if}
    </div>
    
    
    {#if gcalLink}
    <div class="p2 tc w-33 w-15-m">
        <a class="br2 ba b--transparent ph3 pv2 frameShadow bg-homeschool-2 no-underline white" href="{ gcalLink }" target="_blank">Google</a>
    </div>
    {/if}
    {#if o365Link}
    <div class="p2 tc w-33 w-20-m">
        <a class="br2 ba b--transparent ph3 pv2 frameShadow bg-homeschool-2 no-underline white" href="{o365Link}" target="_blank">Office 365</a>
    </div>
    {/if}
    {#if icsLink}
    <div class="p2 tc w-33 w-10-m">
        <button class="br2 ba b--transparent ph3 p-button frameShadow bg-homeschool-2 white pointer" on:click="{ics.download()}">iCal</button>
    </div>
    {/if}
</div>

