import gql from 'graphql-tag'

const CREATE_SESSION = gql`
  mutation createSession($email: Email!) {
    createSession(email: $email) {
      sessionId
      email
      entitlements
      expiry
    }
  }
`

const GET_SESSION = gql`
  mutation getSession {
    getSession {
      sessionId
      userId
      entitlements
      expiry
    }
  }
`

const END_SESSION = gql`
  mutation endSession {
    endSession {
      userId
      entitlements
      expiry
    }
  }
`

const GET_USER = gql`
  query getUser($email: Email!) {
    getUser(email: $email) {
      fname
      sname
      profileImage
      entitlements {
        classId
        dateAdded
        active
        transactionId
      }
    }
  }
`

export { CREATE_SESSION, GET_SESSION, END_SESSION, GET_USER }
