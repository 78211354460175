<script>
    import ClassNav from './ClassNav.svelte'
    import { afterUpdate } from 'svelte'
    export let sections
    export let lessonId
    export let headerColor
    export let name
    export let startDate

    afterUpdate(()=>{
        if (lessonId !== 'welcome' ||  '') {
            const selectedElement = document.getElementById(`list_${lessonId}`)
            if (selectedElement) {
                selectedElement.style.background = '#E1812E'
            }
        }
    })
  //   $: sectionDetail = sections ? sections : []
    $: isOpen = lessonId !== 'welcome' ||  '' ? true : false
  
</script>

<style>

    .courseHead {
        -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 0% 100%);
        clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 0% 100%);
        height: 10rem;
    }

    .frameShadow {
        border-radius: .5rem;
        background: #242424;
        box-shadow:  20px 20px 20px #121212;
    }

    .courseTitle {
        color: white;
        font-size: 2.75rem;
        font-weight: 600rem;
        padding-left: 2rem;
        margin-top: 0rem;
        padding-top: 1rem;
        padding-right: 1rem;
        text-align: left;
    }

</style>

<div class="w-100">
    <div class="w-100 w-90-m w-90-l pb2 center frameShadow">
        <div class="courseHead br3 br--top b--transparent pt0 mt0 pb2" style="background:{headerColor}">
            <h2 class="courseTitle blambastic">{name}</h2>
        </div>
        <div class="relative">
            {#if sections}
                {#if sections.length > 0}
                <div>
                    {#each sections as section}
                        <ClassNav {section} {isOpen} on:message />
                    {/each}
                </div>
                {:else}
                <div class="pv4 white tc">
                    {#if startDate}
                    Course starts on <span class="b">{startDate}</span>
                    {:else}
                    Course has not started yet
                    {/if}
                </div>
                {/if}
            {/if}
        </div>
        
    </div>
</div>
