<script>
    export let videoId
    export let name

</script>

<style>
    [style*="--aspect-ratio"] > :first-child {
    width: 100%;
    }
    @supports (--custom:property) {
        [style*="--aspect-ratio"] {
            position: relative;
        }
        [style*="--aspect-ratio"]::before {
            content: "";
            display: block;
            padding-bottom: calc(100% / (var(--aspect-ratio)));
        }  
        [style*="--aspect-ratio"] > :first-child {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
        }  
    }
</style>

<div id={`ref_${videoId}`} class="relative center w-100" style="--aspect-ratio: 16/9;">
    <iframe class="absolute br3" title={`${name} player`} src={`https://vimeo.com/event/${videoId}`} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
</div>

<!--aspect-ratio
<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://vimeo.com/event/1259239/embed/6a5c5a2489" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>
-->
