import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink } from 'apollo-link'
import { logoutUser } from '../../store/user.js'

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
      if (networkError) {
        if (typeof networkError === 'object') {
          console.log(`[Network error]: ${JSON.stringify(networkError, '', 2)}`)
          if (networkError.statusCode === 401) {
            logoutUser()
            // $goto('/')
            window.location.pathname = '/'
          }
        } else {
          console.log(`[Network error]: ${networkError}`)
        }
      }
    }),
    new HttpLink({
      uri: 'https://api.school.homeschool.dev/graphql',
      credentials: 'include'
    })
    // new HttpLink({
    //   uri: 'https://dev.api.school.homeschool.dev',
    //   credentials: 'include'
    // })
  ]),
  cache: new InMemoryCache()
})

export { client }
