<script>
	import { createEventDispatcher } from 'svelte';
	const dispatch = createEventDispatcher();
</script>

<style>
    .loginBtn {
        background: #ffffff;
        width: 300px;
        height: 54px;
        border: transparent;
        border-radius: .25rem;
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
    .loginBtn:hover {
        cursor:pointer;
    }
    .identityText {
        color: #484848;
        width: 100%;
        font-family: roboto, sans-serif;
        text-align: center;
        font-size: 1.125rem;
        font-weight: 500;
        vertical-align: middle;
    }
    button:focus {
        border-color: transparent!important;
        outline: 0 !important;
    }
    .buttonShadow {
        border-radius: 1rem;
        box-shadow: 10px 10px 10px rgba(10,10,10,0.7);
    }
</style>


<button class="loginBtn buttonShadow" on:click={()=>dispatch('login')}><span class="identityText">Sign in with Username/Password</span></button>