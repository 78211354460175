
/**
 * @roxi/routify 2.18.6
 * File generated Fri Jul 14 2023 21:33:25 GMT+0000 (Coordinated Universal Time)
 */

export const __version = "2.18.6"
export const __timestamp = "2023-07-14T21:33:25.345Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports
import __fallback from '../src/pages/_fallback.svelte'
import _callback from '../src/pages/callback.svelte'
import _classes__course__lessons_index from '../src/pages/classes/[course]/[lessons]/index.svelte'
import _classes__course_index from '../src/pages/classes/[course]/index.svelte'
import _home from '../src/pages/home.svelte'
import _index from '../src/pages/index.svelte'
import _login from '../src/pages/login.svelte'
import _profile from '../src/pages/profile.svelte'
import __layout from '../src/pages/_layout.svelte'

//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isFallback": true,
      "path": "/_fallback",
      "component": () => __fallback
    },
    {
      "isPage": true,
      "path": "/callback",
      "id": "_callback",
      "component": () => _callback
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isIndex": true,
                  "isPage": true,
                  "path": "/classes/:course/:lessons/index",
                  "id": "_classes__course__lessons_index",
                  "component": () => _classes__course__lessons_index
                }
              ],
              "path": "/classes/:course/:lessons"
            },
            {
              "isIndex": true,
              "isPage": true,
              "path": "/classes/:course/index",
              "id": "_classes__course_index",
              "component": () => _classes__course_index
            }
          ],
          "path": "/classes/:course"
        }
      ],
      "path": "/classes"
    },
    {
      "isPage": true,
      "path": "/home",
      "id": "_home",
      "component": () => _home
    },
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => _index
    },
    {
      "isPage": true,
      "path": "/login",
      "id": "_login",
      "component": () => _login
    },
    {
      "isPage": true,
      "path": "/profile",
      "id": "_profile",
      "component": () => _profile
    }
  ],
  "isLayout": true,
  "path": "/",
  "id": "__layout",
  "component": () => __layout
}


export const {tree, routes} = buildClientTree(_tree)

