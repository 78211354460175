<script>
    import { token } from '../../store/user.js'
    import { getClient, query } from 'svelte-apollo';
    import { GET_ATTACHMENT } from '../../js/graphql/queries.js';
    export let attachments = []

    const client = getClient()

    const fetchAttachment = async (location) =>{
        if ($token) {
           

            const queryConfig = { 
                query: GET_ATTACHMENT, 
                variables: { 
                    location
                }, 
                context: {
                    headers: {
                        Authorization: `Bearer ${$token}`
                    }
                } 
            }
            const queryResult = await query(client, queryConfig).result()

            if (queryResult.data) {
                if (queryResult.data.getAttachment) {
                    const { url } = queryResult.data.getAttachment
                    if (url) window.open(url,'_blank')
                }
            }       
        }
    }

</script>

<style>
    .bg-homeschool-button {
        background: #f1f1f1
    }


    .border-underline {
        border-bottom-color: rgba(255,255,255,0.5);
        border-bottom-width: 1px;
        border-bottom-style: dotted;
    }
</style>


<div id={`ref_attachment`} class="relative center w-100 ph3 pt2 pb1 white">
    <div class="w-100 mw7-l center">
        <h2 class="f3 m0">Attachments</h2>
    
        <div class="pb2 mw6 mw7-l">
            {#each attachments as attachment, i}
                <div class="pr2 pt3 pb2 flex items-center justify-between border-underline">
                    <div>{attachment.filename}</div><button id="{`download_${i}`}" class="dark-gray b b--transparent bg-homeschool-button ph2 pv1 br2 pointer" on:click={()=>fetchAttachment(attachment.location)}>Download</button>
                </div>
            {/each}
        </div>
    </div>
</div>
