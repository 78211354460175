import { writable } from './localStore.js'
import { get } from 'svelte/store'
import { mutate, query } from 'svelte-apollo'
import { CREATE_SESSION, END_SESSION, GET_USER } from './userQueries.js'
import { client } from '../js/graphql/client.js'

const user = writable('user', { isAuthenticated: false })
const token = writable('token', '')
const validation = writable('validation', { store: '', nonce: '' })

const setToken = authToken => {
  token.set(authToken)
}

const setUser = async idToken => {
  let userResult = false
  const userStore = get(user)

  try {
    /*
    if (userStore) {
      if (userStore.expiry) {
        if (userStore.expiry < Date.now()) {
          console.log('Session exists. Overwriting.')
        }
      }
    }
    */

    // const client = getClient()
    const { claims } = idToken
    const { email } = claims

    const userToken = await get(token)

    const createSession = {
      mutation: CREATE_SESSION,
      variables: {
        email
      },
      context: {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      }
    }

    const sessionResponse = await mutate(client, createSession)

    if (sessionResponse) {
      
      const getUser = {
        query: GET_USER,
        variables: {
          email
        },
        context: {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        }
      }

      const userResponse = await query(client, getUser).result()

      let name = ''
      if (userResponse) {
        // console.log(userResponse)
        if (userResponse.data.getUser) {
          name = `${
            userResponse.data.getUser.fname
              ? userResponse.data.getUser.fname
              : ''
          } ${
            userResponse.data.getUser.sname
              ? userResponse.data.getUser.sname
              : ''
          }`
        }
      }

      const entitlements = sessionResponse.data.createSession
        ? sessionResponse.data.createSession.entitlements
          ? sessionResponse.data.createSession.entitlements
          : []
        : []

      const sessionId = sessionResponse.data.createSession
        ? sessionResponse.data.createSession.sessionId
          ? sessionResponse.data.createSession.sessionId
          : false
        : false

      const expiry = sessionResponse.data.createSession
        ? sessionResponse.data.createSession.expiry
          ? sessionResponse.data.createSession.expiry
          : false
        : false

      // console.log(JSON.stringify(entitlements))

      const userDetails = {
        name,
        email,
        entitlements,
        sessionId,
        expiry,
        isAuthenticated: true
      }
      user.set(userDetails)
      userResult = true
    }
  } catch (error) {
    console.error(error)
  }
  return userResult
}

const logoutUser = async () => {
  try {
    // const client = getClient()
    const userToken = get(token)
    if (userToken) {
      const endSession = {
        mutation: END_SESSION,
        context: {
          headers: {
            Authorization: `Bearer ${userToken}`
          }
        }
      }

      try {
        await mutate(client, endSession)
      } catch (error) {
        console.error('logout Error')
        console.error(error)
      }
    }
  } catch (error) {
    console.error(error)
  }
  clearCookies()
  const userDetails = false
  user.set(userDetails)
  token.set('')

  // console.log(`endSession: ${JSON.stringify(sessionResponse, '', 2)}`)
}

const clearCookies = () => {
  const allCookies = document.cookie.split(';')
  for (let i = 0; i < allCookies.length; i++) {
    document.cookie = allCookies[i] + '=;expires=' + new Date(0).toUTCString()
  }
}

const getCookieValue = key => {
  var value = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)')
  return value ? value.pop() : ''
}

const checkAuth = () => {
  let authState = false
  try {
    const userState = get(user)

    if (!userState) throw new Error('No user state')
    if (!userState.expiry) throw new Error('No expiry set')

    const currentTimeStamp = Date.now()
    if (userState.expiry < currentTimeStamp) throw new Error('Session expired')
    if (!userState.isAuthenticated) throw new Error('Not authenticated')

    const sessionId = getCookieValue('sessionId')
    // console.log(sessionId)
    // if (!sessionId) throw new Error('No sessionId')

    const authToken = get(token)
    if (!authToken) throw new Error('No access token')

    authState = true
  } catch (error) {
    user.set({ isAuthenticated: false })
    // console.log(`Authentication failed. \n${error}`)
  }
  return authState
}

export { setUser, logoutUser, user, setToken, checkAuth, token, validation }
