<script>
    import { slide } from "svelte/transition";
    export let lesson
    export let heightVar

    $:  contents = lesson ? lesson.contents ? lesson.contents : [] : []

</script>

<style>
    .timeBox {
        width: 4rem;
    }
    .menu-content {
        overflow: hidden;
        margin-left: 0%;
        padding-left: 0%
    }
</style>

<div class="dn db-m db-l w-100">
    {#if contents.length > 0 }
        <ul class="menu-content list p0" transition:slide|local={{ duration: 300 }} style={`max-height:${heightVar}`}>
            {#each contents as content}
                {#if content.type === 'markdown'}
                    <li id={`lesson_${content.location.substring(content.location.indexOf('/') + 1, (content.location.length-3))}`} class="mv2 ml3 pl2">
                        <a class="no-underline white" href={`#ref_${content.location.substring(content.location.indexOf('/') + 1, (content.location.length-3))}`}>Exercise</a>
                    </li>
                {:else if content.type === 'attachment'}
                    <li id="lesson_attachment" class="mv2 ml3 pl2">
                        <a class="no-underline white" href="#ref_attachment">Attachments</a>
                    </li>
                {:else if content.type === 'video'}
                    <li id={`lesson_${content.videoId}`} class="mv2 mh3 pl2 flex items-start justify-between">
                        <div>
                            <a class="no-underline white" href={`#ref_${content.videoId}`}>Lecture</a>
                        </div>
                        <div class="timeBox white">{content.time}</div>
                    </li>
                {/if}
            {/each}
        </ul>
    {/if}
</div>