<script>
    export let course
    import { goto } from '@roxi/routify'

    const loadCourse = () => {
        if (course) {
            if (course.id) {
                $goto(`/classes/${course.id}/welcome`)
            }
        }
    }

    $: state = {
        id: course ? course.id ? course.id : '' : '',
        name: course ? course.name ? course.name : '' : '',
        startDate: course ? course.startDate ? course.startDate : '' : '',
        headerColor: course ? course.headerColor ? course.headerColor : '#FF9D00' : '#FF9D00',
        snippet: course ? course.snippet ? course.snippet : '' : '',
        duration: course ? course.duration ? course.duration : '' : '',
        link: course ? course.id ? course.id.slice(0, course.id.lastIndexOf('-')) : '' : '',
        instructors: course ? course.instructor ? course.instructor : [] : [],
        available: course ? course.startDate ? new Date(course.startDate) : false : false
    }
</script>


<style>
    .frameShadow {
        border-radius: 1rem;
        box-shadow:  20px 20px 20px #121212;
    }

    .bg-homeschool-1 {
        background: #242424
    }

    .card {
        width: 18rem;
        height: 25rem;
    }
    .cardHead {
        -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 0% 100%);
        clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 0% 100%);
        height: 8rem;
    }

    h2 {
        font-size: 1.75rem;
    }

    .cardFooter {
        position: absolute;
        bottom: 1rem;
    }
    
</style>

<a class="no-underline pointer grow" href={`/classes/${state.id}/welcome`}>
    <div class="relative card bg-homeschool-1 ba br3 b--transparent frameShadow white">
        <div class="cardHead br3 br--top b--transparent" style="background: { state.headerColor };" >
            <h2 class="mv0 pv2 ph3">
                {state.name}
            </h2>
        </div>
        
        {#if state.instructors}
            {#each state.instructors as instructor}
            <div class="pv1 pr3 f5 tr">
                {instructor.name}
            </div>
            {/each}
        {/if}
    
        <div class="lh-copy ph3 pt3">
            {state.snippet}
        </div>
        
        
        
        <div class="cardFooter">
            <div class="f45 b ph3">{state.startDate}</div>
            <div class="f45 ph3 pt2">{state.duration}</div>
        </div>
    </div>
</a>
