<script>
import { callback } from '@esmodule/openid-implicit-grant'
import { onMount } from 'svelte'
import { goto } from '@roxi/routify'
import { validation, setToken, setUser } from '../store/user.js'

let isLoading = true
const authValidation = typeof validation.subscribe == 'function' ? $validation : { state: '', nonce: ''}
const returnHash = window.location.href.split('#')[1] || ''


onMount(async ()=>{
    const { idToken, accessToken } = callback(returnHash, authValidation)
    
    if (returnHash) {
        if (idToken) {
            setToken(accessToken.token)
            const userResult = await setUser(idToken)
            validation.set({ state: '', nonce: ''})
            if (userResult) { 
                $goto('/home') 
            }
        } else {
            $goto('/')
        }
    } else {

    }
    isLoading = false
})



// access_token=CITA-0En1n7ZTw8uwexbOEhfm2-_vVHz&scope=openid%20profile&expires_in=7200&token_type=Bearer&state=GfXXLmpEE_06PYDkNdP4ZOBUjiuxLpmFG~U3o~pg1_FQ-sbvy3QRiY4cnhOI81~O&id_token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlJqWTVORFpFTmtJNU9EazVNalEwUTBZelEwUkVNemRGTnpsRE1VWXlSREJDUmpSQ1FVVkdOZyJ9.eyJnaXZlbl9uYW1lIjoiQW50aG9ueSIsImZhbWlseV9uYW1lIjoiU3RhbmxleSIsIm5pY2tuYW1lIjoiYW50IiwibmFtZSI6IkFudGhvbnkgU3RhbmxleSIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS0vQU9oMTRHaVJyZG9HNVVXUm8yQUJOd3J0RUpaSWpTOElLQl9yUTBGU213NE8iLCJsb2NhbGUiOiJlbiIsInVwZGF0ZWRfYXQiOiIyMDIwLTA3LTE1VDIzOjA0OjIzLjU1M1oiLCJpc3MiOiJodHRwczovL2F1dGguaG9tZXNjaG9vbC5kZXYvIiwic3ViIjoiZ29vZ2xlLW9hdXRoMnwxMTYzOTQxODAxMzYzMTE0MDkyNTUiLCJhdWQiOiJ6UjUxaEdpSTcwQWpselBpVkxXTjJnOXBETFVySXZUcSIsImlhdCI6MTU5NDg1NDI2MywiZXhwIjoxNTk0ODkwMjYzLCJhdF9oYXNoIjoiM1NwaVBHLWJQd2hwOGlNV1lZcDF3ZyIsIm5vbmNlIjoiSXBfaS5ndTZDanZqS2o4MWVpRmlWMXpwTE50eEpxUzMifQ.Nk2cYHAX65AokeUmm4EtQnKon2FAK2x-6FvTanG305niSLWIOtp6hY0YI8YrnwCySuvZjX6rdpYigLOVIQQmB5TUGaMwBgTDHCVHWOnbN_yGTzIRjsbvJXdWLvk0Di6UfJ_83DH-4G0mADjMDeSYZcurtX8SbaVxWLJxJH-vnhBscMruL8T5q_W6QMcHB_WpajfY3_w9FtO-84QhPksyXxfBuqrqitt__a7C8GEsUZEmCoGeEI-GORN6Dj3xw3zsI2WJ7Yd0zUKQqNe4S_IyWmmKHIB8Rt1fNjBQUY2Et4TRlGzg5v5EliN43zA31CaNJq__nQdnMBIjpv3I5mEb2Q

</script>

<style>
    .loader {
        border: 9px solid #505050; /* Light grey */
        border-top: 9px solid #909090; /* Blue */
        border-radius: 50%;
        width: 90px;
        height: 90px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

</style>
    {#if isLoading}
        <div class="dt w-100 vh-75">
            <div class="dtc w-100 v-mid tc center">
                <div class="loader center"></div> 
            </div>
        </div>
    {/if}