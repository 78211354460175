<script>
    import { goto } from '@roxi/routify'
   
    export let course

    const courseId = course ? course : '' 

    const courseList = ['production-ready-serverless-202205', 'production-ready-serverless-202005','production-ready-serverless-202009', 'production-ready-serverless-202101', 'production-ready-serverless-202104', 'production-ready-serverless-202109','production-ready-serverless-202201', 'testing-serverless-apps-202006', 'reinvent-2020-review-202012', 'containers-on-aws-202011', 'nodejs-for-serverless-202101' ]
    const validCourse = (courseList.filter(course => { 
        return courseId === course }).length > 0) ? true : false

    if (!validCourse) {
        $goto('/')
    } else {
        $goto(`/classes/${course}/welcome`)
    }

  

</script>

