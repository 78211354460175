<script>
    import Dropdown from './Dropdown.svelte'
    import { url, goto } from '@roxi/routify'
    import { user, logoutUser } from '../../store/user.js'

    const logout = async () => {
        try {
            await logoutUser()
        } catch (error) {
            console.log(`Logout error: ${error}`)
        }
        $goto('/')
    }
    
</script>

<style>
    button:focus {
        border-color: transparent!important;
        outline: 0 !important;
    }
</style>

<div class="p2">
    {#if $user }
        {#if $user.isAuthenticated}
            <Dropdown class="pa2">
                {#if $user.name }
                    <button class="bn white bg-homeschool f45 p2 pointer">{ $user.name }</button>
                {/if}
            <div slot="dropdown" class="pr2 mv2 f5 tr"><button class="no-underline bg-homeschool p0 bn white pointer" on:click={()=>logout()}>Logout</button></div>
            </Dropdown>
        {/if}
    {/if}
</div>