<script>
    import TopNav from './_components/TopNav.svelte'
    // import { createAuth } from '../auth.js'
    // 
    // const {
    //     isAuthenticated,
    //     authToken
    // } = createAuth();

</script>

<div>
    <TopNav />
    <div class="w-100 dt vh-75">
        <div class="dtc w-100 v-mid tc f4 white">
               
        </div>
    </div>
</div>