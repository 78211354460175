<script>
    import CurrentCourse from '../_components/CurrentCourse.svelte'
    import CourseCard from '../_components/CourseCard.svelte'
    import PreviousCourseCard from '../_components/PreviousCourseCard.svelte'

    export let dashboard

    $: current = dashboard ? dashboard.data ? dashboard.data.getUserDashboard ? dashboard.data.getUserDashboard.current ? dashboard.data.getUserDashboard.current : [] : [] : [] : []
  //  $: purchased = dashboard ? dashboard.data ? dashboard.data.getUserDashboard ? dashboard.data.getUserDashboard.purchased ? dashboard.data.getUserDashboard.purchased : [] : [] : [] : []
    $: previous = dashboard ? dashboard.data ? dashboard.data.getUserDashboard ? dashboard.data.getUserDashboard.previous ? dashboard.data.getUserDashboard.previous : [] : [] : [] : []
    $: upcoming = dashboard ? dashboard.data ? dashboard.data.getUserDashboard ? dashboard.data.getUserDashboard.upcoming ? dashboard.data.getUserDashboard.upcoming : [] : [] : [] : []
    
</script>

<div class="w-100 ph2">
    {#if current}
        {#if current.length > 0}
        <div class="w-100 w-90-m w-90-l mt1 ph2 tl center">
            <h2 class="white fw6 mt0 mb2 f3">Current</h2>
            <div class="w-100">
                {#each current as currentCourse}
                    <CurrentCourse course={currentCourse} />
                {/each}
            </div>
        </div>
        {/if}
    {/if}

    {#if upcoming}
        {#if upcoming.length > 0}
        <div class="w-100 w-90-m w-90-l pt3 tl center">
            <h2 class="white fw6 f3 m0 pb0 pt2 tc tl-m">Upcoming Courses</h2>
            <div class="w-100 pt2 pb5 flex flex-wrap justify-center justify-start-m">
                    {#each upcoming as upcomingCourse}
                    <div class="pr3-m pr4-l pv2 pv0-m pv0-l mt4">
                        <CourseCard course={upcomingCourse}/>
                    </div>
                    {/each}
            </div>
        </div>
        {/if}
    {/if}

    <div class="w-100 w-90-m w-90-l pt3 center">
        <h2 class="white fw6 f3 m0 pb0 pt2 tc tl-m">Previous Courses</h2>
        <div class="w-100 pt2 pb5 flex flex-wrap justify-center justify-start-m">
            {#if previous}
                {#each previous as previousCourse}
                <div class="pr3-m pr4-l pv2 pv0-m pv0-l mt4">
                    <PreviousCourseCard course={previousCourse}/>
                </div>
                {/each}
            {/if}
        </div>
    </div>
    
</div>