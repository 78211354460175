import * as Prism from './prism.js'

const loadPrismLang = lang => {
  lang = lang === 'yml' ? 'yaml' : lang
  let langObject = Prism.languages[lang]
  if (typeof langObject == 'undefined') {
    return ''
    // try {
    //   const s = document.createElement('script')
    //   s.src = `/assets/prism/components/prism-${lang}.js`
    //   s.async = true
    //   s.onload = function () {
    //     // document.body.removeChild(s)
    //     // success && success()
    //   }
    //   s.onerror = function () {
    //     document.head.removeChild(s)
    //     // error && error()
    //   }
    //   document.head.appendChild(s)
    //   return Prism.languages[lang]
    // } catch (e) {
    //   console.log(e)
    // }
  } else {
    return langObject
  }
}

const highlight = (text, lang) => {
  const prismLang = lang ? loadPrismLang(lang) : false
  const code = prismLang ? Prism.highlight(text, prismLang) : text
  const classAttribute = lang ? ` class="language-${lang}"` : ''
  return `<pre${classAttribute}><code${classAttribute}>${code}</code></pre>`
}

export default highlight
