<script>
    import CurrentWeek from './CurrentWeek.svelte'
    import { goto } from '@roxi/routify'

    export let course

    const loadCourse = () => {
        if (course) {
            if (course.id) {
                $goto(`/classes/${course.id}/welcome`)
            }
        }
    }

    $: state = {
        name: course ? course.name ? course.name : '' : '',
        startDate: course ? course.startDate ? course.startDate : '' : '',
        snippet: course ? course.snippet ? course.snippet : '' : '',
        link: course ? course.id ? course.id.slice(0, course.id.lastIndexOf('-')) : '' : '',
        instructors: course ? course.instructor ? course.instructor : [] : []
    }
</script>

<style>
    .frameShadow {
        border-radius: 1rem;
        box-shadow:  20px 20px 20px #121212;
    }

    .bg-homeschool-1 {
        background: #242424
    }
</style>


<div class="w-100-s mw8-m mw8-l mt3 p3 ba br3 b--transparent frameShadow bg-homeschool-1">
    
        <div class="w-100 white pt1 bb b--white-60 bw1 flex flex-wrap items-start justify-between">
            <div>
                <h3 class="p0 mv2 f4">{state.name}</h3>
            </div>
            <div class="f5 b mv2">
                { state.startDate }
            </div>
            
        </div>
        <div class="white flex flex-wrap items-start justify-between pv1">
            <div class="pv1 lh-copy w-80 mw5-m mw6-l">
                { state.snippet }
            </div>
            <div>
            {#if state.instructors}
                {#each state.instructors as instructor}
                <div class="pv2 f45 ph1 tr">
                    {instructor.name}
                </div>
                {/each}
            {/if}
        </div>
        </div>
        
        
        <!--
        <div class="w-100 v-top pt3">
            <CurrentWeek />
        </div>
        -->
        <div class="pt3 cf">
            <button class="pv2 ph4 pointer mt2 f5 bg-green-3 white tc ba br0 b--transparent" on:click={()=> loadCourse()}>Go to course</button>
        </div>

</div>