<script>

import { login } from '@esmodule/openid-implicit-grant'
import { authentication } from '../../config/index.js'
import { setContext } from 'svelte'

// import {createEventbusDispatcher} from './_components/EventBus.svelte';
// const dispatch = createEventbusDispatcher();

const loginConfig = login(authentication)

if (loginConfig) {
    setContext('validation', loginConfig.validation)
}

const authUrl = loginConfig ? loginConfig.url ? loginConfig.url : '' : ''

const loginButton = () => {
    if (authUrl) {
        window.location.href = authUrl
    } else {
        console.error('authUrl not specified')
    }
}

</script>

<style>
    .frameShadow {
        border-radius: 1rem;
        box-shadow:  20px 20px 20px #121212;
    }

    .bg-homeschool-1 {
        background: #242424
    }
</style>

<div>
    
    <div class="relative">
        <div class="w-100 dt vh-100">
            <div class="dtc w-100 v-mid tc f4 white">
                <button class="ph5 pv4 b f4 white ba br2 b--transparent frameShadow bg-homeschool-1 pointer" on:click={()=>{loginButton()}}>Login</button>
            </div>
        </div>
    </div>
</div>